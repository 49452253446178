<template>
  <v-navigation-drawer
    v-model="$store.state.app.drawer"
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    width="210"
  >
    <!-- :clipped="$vuetify.breakpoint.lgAndUp" -->
    <div class="nav-logo">
      <v-app-bar-nav-icon
        @click.stop="toggleDrawer"
        class="ml-0"
      ></v-app-bar-nav-icon>
      <v-btn
        class="hidden-sm-and-down logo"
        text
        to="/"
      >
        <!-- <img src="@/assets/logo.jpg" alt="logo" /> -->
        <img
          src="@/assets/thetik-logo.png"
          alt="logo"
          width="500"
          height="230"
        />
        <span class="mt-5"><b>E-Contract</b> {{ title }}</span>
      </v-btn>
    </div>
    <v-list
      dense
      expand
    >
      <template v-for="item in filteredItems">
        <v-list-group
          v-if="item.children"
          :key="item.text"
          v-model="item.model"
          no-action
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.to"
            active-class="font-italic"
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ child.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else
          :key="item.text"
          :to="item.to"
          active-class="font-italic"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { UserRole } from "./../../js/constants";
import { mapMutations } from "vuex";
import { getToken } from "./../../js/authorization";

export default {
  data: () => ({
    items: [],
    drawer: null,
  }),
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
  created() {
    this.initItems();
  },
  watch: {
    $route: {
      handler(to) {
        this.setVListGroupExpand();
      },
      immediate: true,
    },
    items: {
      handler(to) {
        this.setVListGroupExpand();
      },
    },
  },
  methods: {
    setVListGroupExpand() {
      let targetString = "";
      if (this.$route.matched[0]) {
        targetString = this.$route.matched[0].path;
      }

      this.items.forEach((item) => {
        if (item.children) {
          item.model = false;
          var hasChild = item.children.find((x) => targetString.includes(x.to));

          if (hasChild) {
            item.model = true;
          }
        }
      });
    },
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    onClick() {
      // this.setDrawer(!this.$store.state.app.drawer);
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
    initItems() {
      this.items = [
        {
          icon: "mdi-view-grid-plus",
          text: "บิลห้องเช่าหัวนา",
          to: "/bill-huana",
          permission: ["*"],
        },
        {
          icon: "mdi-view-grid-plus",
          text: "Excel Clipboard to Template",
          to: "/excel-clipboard-to-template",
          permission: ["*"],
        },
      ];
    },
    checkPermission(item) {
      let result = this.hasPermission(item.permission);
      return result;
    },
  },
  computed: {
    title() {
      // return `${process.env.VUE_APP_NAME} [${process.env.VUE_APP_SERVER}]`;
      return `[${process.env.VUE_APP_SERVER}]`;
    },
    filteredItems() {
      let singleLayers = this.items.filter(
        (x) => !x.hasOwnProperty("children")
      );
      let nestedLayers = this.items.filter(
        (x) => x.hasOwnProperty("children") && x.children.length > 0
      );

      let resultFilterd = [];
      //single layer
      let rusultSingle = singleLayers.filter((x) => this.checkPermission(x));
      resultFilterd.push(...rusultSingle);

      //nested layer
      nestedLayers.forEach((item) => {
        item.children = item.children.filter((x) => this.checkPermission(x));
        if (item.children.length > 0) {
          resultFilterd.push(item);
        }
      });

      return resultFilterd;
    },
  },
};
</script>
